import { ITradeContextTradesProp } from "1-quarks/context/User/context";
import { arrayIsUnique, arrayRemoveDuplicates } from "./array";

/**
 * Filter Open/Close trade status
 * @param trades all the trades to be filtered
 * @param returnClosed when true, only return closed trade, when false return open trade
 */
export const filterOpenCloseTradeStatus = (
  trades: ITradeContextTradesProp,
  returnClosed: boolean
): ITradeContextTradesProp => {
  const keys = Object.keys(trades);
  const result: ITradeContextTradesProp = {};
  keys.forEach((k) => {
    const isTradeClosed = trades[k].closePrice || -1 > 0;
    if (isTradeClosed === returnClosed) result[k] = { ...trades[k] };
  });
  return result;
};

/**
 * filter trade based on tags
 * @param trades trades to go through
 * @param tags list of tags to check
 * @param includeElseExcludeTags to include trades that has tags, or exclude if false
 */
export const filterTradeWithTags = (
  trades: ITradeContextTradesProp,
  tags: string[],
  includeElseExcludeTags: boolean
): ITradeContextTradesProp => {
  const keys = Object.keys(trades);
  const uniqueTags = arrayRemoveDuplicates(tags);
  const result: ITradeContextTradesProp = {};
  keys.forEach((k) => {
    const tmpTags = [...uniqueTags, ...arrayRemoveDuplicates(trades[k].tags)];
    //unique means no matching tag
    if (arrayIsUnique(tmpTags) !== includeElseExcludeTags)
      result[k] = { ...trades[k] };
  });
  return result;
};
