import moment from "moment";

//bonds
import {
  ITradeNewProps,
  ITradeModelProps,
  ITradeLocalProps,
} from "0-bonds/interface/trade";
import { ITags } from "0-bonds/interface/tag";
import { ISymbols } from "0-bonds/interface/symbol";

//quarks
import { fireDb } from "1-quarks/firebase";
import {
  IAuthContextPerson,
  IUserContextDispatch,
  helperSymbolContextSet,
} from "1-quarks/context/User";
import { GrossProfit, NetProfit, RoundUp } from "1-quarks/helper/calculation";
import { TradesContextActionSet } from "1-quarks/context/User/action";

import useUserContextSession from ".";
import { helperTagContextSet } from "./helper-tags";

//helper
import st from "1-quarks/sneaky-ton";
const path = "/1-quarks/context/User/helper-trades";

export const useTradeContextGet = () => {
  st.run({ type: "start", path, fn: "useTradeContextGet" });
  const { UserContext } = useUserContextSession();
  if (!("trades" in UserContext)) return {};

  return UserContext["trades"];
};

export const useTradeContextGetTrade = (
  id: string
): ITradeLocalProps | undefined => {
  const trades = useTradeContextGet();
  if (id in trades) return trades[id];
  return undefined;
};

export const useTradeContextGetArray = () => {
  st.run({ type: "start", path, fn: "useTradeContextGetArray" });
  const trades = useTradeContextGet();

  return Object.keys(trades).map((k) => {
    return { ...trades[k], id: k };
  });
};

/**
 * Internal function to set trade context
 * @param snapshot firebase snapshot to load data
 */
const TradesContextSet = (dispatchUserContext: IUserContextDispatch) => (
  snapshot: firebase.database.DataSnapshot
) => {
  const result: {
    [key: string]: ITradeLocalProps;
  } = {};
  const symbols: ISymbols = {};
  const tags: ITags = {};

  snapshot.forEach(TradeConvertSnapshotToLocal(result));
  Object.keys(result).forEach((k) => {
    symbols[result[k].symbol || "unknown"] = "";
    if (result[k].tags && result[k].tags.length > 0) {
      result[k].tags.forEach((t) => {
        tags[t] = "";
      });
    }
  });

  helperSymbolContextSet(dispatchUserContext, symbols);
  helperTagContextSet(dispatchUserContext, tags);
  dispatchUserContext(TradesContextActionSet(result));
};

/**
 * Adds trade to db, helper function to perform trade add function
 * @param auth
 * @param trade trade to be added
 */
export const helperTradesAdd = (
  auth: IAuthContextPerson | null | undefined,
  trade: ITradeNewProps
) => {
  if (auth === null || auth === undefined) return;
  const modelTrade = TradeConvertNewToModel(auth, trade);
  GetRef(auth).push(modelTrade);
};

/**
 * Adds trade to db, helper function to perform trade add function
 * @param auth
 * @param trade trade to be added
 */
export const helperTradesEdit = (
  auth: IAuthContextPerson | null | undefined,
  id: string,
  trade: ITradeLocalProps
) => {
  if (auth === null || auth === undefined) return;
  const modelTrade = TradeConvertLocalToModel(trade);
  GetRef(auth)
    .child(id)
    .set(modelTrade);
};
////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////db////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Get db reference for user
 * @param auth user auth in context
 */
const GetRef = (auth: IAuthContextPerson | null | undefined, path?: string) => {
  const collName = auth !== null && auth !== undefined ? auth._id : "error";

  return fireDb.ref(
    process.env.REACT_APP_DB_PREFIX + "/" + collName + (path ? "/" + path : "")
  );
};
/**
 * subscribe to trade
 * @param auth user auth
 */
export const helperTradesSubscribe = (
  dispatchUserContext: IUserContextDispatch,
  auth: IAuthContextPerson | null | undefined
) => {
  // Attach an asynchronous callback to read the data at our posts reference
  GetRef(auth).on(
    "value",
    TradesContextSet(dispatchUserContext),
    (errorObject: Object) => {
      dispatchUserContext(TradesContextActionSet({}));
      if (auth) console.log("DB read failed: ", auth, errorObject);
    }
  );
};

/**
 * unsubscribe from trade
 * @param auth user auth
 */
export const helperTradesUnsubscribe = (
  dispatchUserContext: IUserContextDispatch,
  auth: IAuthContextPerson | null | undefined
) => {
  GetRef(auth).off("value", TradesContextSet(dispatchUserContext));
};

////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////conversion////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Convert new trade to model format
 * @param auth Auth context
 * @param trade new trade
 */
const TradeConvertNewToModel = (
  auth: IAuthContextPerson,
  trade: ITradeNewProps
): ITradeModelProps => {
  return {
    uid: auth._id,
    createTs: moment().toISOString(),
    updateTs: moment().toISOString(),
    ...trade,
    openPrice: trade.openPrice,
  };
};

/**
 * Convert new trade to model format
 * @param auth Auth context
 * @param trade new trade
 */
const TradeConvertLocalToModel = (
  trade: ITradeLocalProps
): ITradeModelProps => {
  //remove from local props
  const { id, profitGross, profitNet, directionalSize, ...rest } = trade;
  return {
    ...rest,
    updateTs: moment().toISOString(),
  };
};

/**
 * Convert trade model to trade local
 * @param trade trade model
 */
const TradeConvertSnapshotToLocal = (output: {
  [key: string]: ITradeModelProps;
}) => (snapshot: firebase.database.DataSnapshot) => {
  const tmp = snapshot.val();
  //populate with initialise props
  const t: ITradeLocalProps = {
    ...tmp,
    profitGross: 0,
    profitNet: 0,
    directionalSize: tmp.directionLong ? tmp.size : -tmp.size,
  };

  //calculate profit
  if (t.closePrice && t.closePrice > 0) {
    t["profitGross"] = RoundUp(
      GrossProfit(t.openPrice, t.closePrice, t.size, t.directionLong),
      2
    );
    t["profitNet"] = RoundUp(NetProfit(t.profitGross, t.commission), 2);
  }

  //auto tagging - system tag always start with "."
  output[snapshot.key || "error"] = t;
};
