/**
 * Check if array has duplicate values
 * @param arr array to check
 */
export const arrayIsUnique = (arr: any[]) => {
  return arr.length === new Set(arr).size;
};

/**
 * Remove duplicates from array
 * @param arr array to be converted
 */
export const arrayRemoveDuplicates = (arr: any[]) => {
  return Array.from(new Set(arr));
};
