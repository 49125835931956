import React from "react";

import DashboardTemplate from "5-templates/dashboard";
import { useTradeContextGet, useTagContextGet } from "1-quarks/context/User";
import {
  filterOpenCloseTradeStatus,
  filterTradeWithTags,
} from "1-quarks/helper/trades";

import st from "1-quarks/sneaky-ton";
const path = "/6-pages/dashboard";

const DashboardPage = () => {
  st.run({ type: "start", path });
  const [includes, setIncludes] = React.useState<string[]>([]);
  const [excludes, setExcludes] = React.useState<string[]>([]);
  const trades = filterTradeWithTags(
    filterTradeWithTags(
      filterOpenCloseTradeStatus(useTradeContextGet(), false),
      excludes,
      false
    ),
    includes,
    true
  );
  const tags = Object.keys(useTagContextGet());
  const props = {
    headerTitle: "Dashboard",
    trades,
    tags,
    includeTags: includes,
    excludeTags: excludes,
    onIncludeValue: (tags: string[]) => setIncludes(tags),
    onExcludeValue: (tags: string[]) => setExcludes(tags),
  };
  return <DashboardTemplate {...props} />;
};

export default DashboardPage;
